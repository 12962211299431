import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../utils/graphql'

import WysiwygPage from '../../components/Templates/WysiwygPage'
import NoTranslation from '../../components/Templates/NoTranslation'
import { withPreview } from 'gatsby-source-prismic'

class HubspotForm extends Component {
  componentDidMount () {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5068065',
          formId: '6c2c6741-bc92-4cf2-925b-89262ceeb70f',
          target: '#hubspotForm'
        })
      }
    })
  }

  render () {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    )
  }
}

const EthereumVsPage = ({ data, pageContext }) => {
  const { prismicWhyBuildOnAlgorand } = data
  const page = prismicWhyBuildOnAlgorand
  if (!page) return (<NoTranslation></NoTranslation>)

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      isThick: true
    }),
    content: page.data.content.html
  }
  return (
    <WysiwygPage { ...hydratedData }>
      <HubspotForm />
    </WysiwygPage>
  )
}

export default withPreview(EthereumVsPage)

export const pageQuery = graphql`
  query WhyBuildOnAlgorandByLocale( $locale: String! ) {
    prismicWhyBuildOnAlgorand( lang: { eq: $locale } ) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        content {
          html
        }
      }
    }
  }
`
